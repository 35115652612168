<template>
  <div class="main__dashboard">
    <!-- Start of top hearder component -->
    <div class="header">
      <div class="header__logo">
        <img src="../assets/afcfta_caravan.png" height="60" />
      </div>
      <div class="header__searchbar">
        <input class="input" type="text" placeholder="search" />
        <button class="button"><i class="fas fa-search"></i></button>
      </div>
      <div class="header__links">
        <ul>
          <li><a href="">Blog</a></li>
          <li><a href="">Debate</a></li>
          <li><a href="">Challenge</a></li>
        </ul>
      </div>
      <div class="header__icons">
        <button><i class="far fa-envelope"></i></button>
        <button><i class="fas fa-bell mr-4"></i></button>
        <div class="avatar">
          <p class="avatar__text pr-3">
            {{
              currentUser
                ? currentUser.first_name + " " + currentUser.last_name
                : ""
            }}
          </p>
          <div
            class="avatar__avatar mt-1"
            style="cursor:pointer"
            @click="logout"
          >
            <p class="avatar-text" v-if="!currentUser.avatar">
              <i class="fas fa-user-tie"></i>
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- End of top header -->
    <div class="header__content">
      <div class="header__content__sidebar">
        <h2 class="header__content__sidebar__header">AfCFTA Caravan</h2>
        <div class="header__content__sidebar__links">
          <ul class="header__content__sidebar__links_ul">
            <li
              class="header__content__sidebar__links_ul_li"
              v-for="(link, index) in dashboard.dashboard.links.sidebar"
              :key="index"
              :class="[$route.name === link.active ? 'active' : '']"
            >
              <router-link
                class="header__content__sidebar__links_ul_li_a"
                :to="getRoute(link.route)"
                :class="[$route.name === link.active ? 'green' : '']"
              >
                <i :class="`${link.icon}`"></i>
                <span
                  class="pl-2
                          "
                  >{{ link.text }}</span
                >
              </router-link>
            </li>

            <li>
              <div class="row">
                <div class="col">
                  <button
                    class="main__header"
                    @click="showProfileManagement = !showProfileManagement"
                  >
                    <i class="far fa-id-card mr-2"></i>Profile Management
                    <i
                      class="fas fa-chevron-up"
                      v-if="showProfileManagement"
                    ></i>
                    <i class="fas fa-chevron-down" v-else></i>
                  </button>
                </div>
                <div class="col hidden_content" v-if="showProfileManagement">
                  <li class="header__content__sidebar__links_ul_li pl-2">
                    <router-link
                      class="header__content__sidebar__links_ul_li_a opacity{"
                      to="/admins/entities"
                    >
                      <i class="fas fa-sitemap mr-2"></i>Entities</router-link
                    >
                  </li>
                  <li class="header__content__sidebar__links_ul_li pl-2">
                    <router-link
                      class="header__content__sidebar__links_ul_li_a opacity{"
                      to="/admins/users"
                      ><i class="fas fa-users mr-2"></i>Users</router-link
                    >
                  </li>
                </div>
              </div>
            </li>
            <li>
              <div class="row">
                <div class="col">
                  <button
                    @click="showMandate = !showMandate"
                    class="main__header"
                  >
                    <i class="fas fa-braille mr-2"></i>Mandates
                    <i v-if="showMandate" class="fas fa-chevron-up"></i>
                    <i class="fas fa-chevron-down" v-else></i>
                  </button>
                </div>
                <div class="col hidden_content" v-if="showMandate">
                  <li class="header__content__sidebar__links_ul_li pl-2">
                    <router-link
                      class="header__content__sidebar__links_ul_li_a opacity{"
                      to="/organisations/give_mandate"
                      ><i class="fas fa-sitemap mr-2"></i
                      >Organisations</router-link
                    >
                  </li>
                  <li class="header__content__sidebar__links_ul_li pl-2">
                    <router-link
                      class="header__content__sidebar__links_ul_li_a opacity{"
                      to="/agencies/give_mandate"
                      ><i class="fas fa-users mr-2"></i>Agencies</router-link
                    >
                  </li>
                  <li class="header__content__sidebar__links_ul_li pl-2">
                    <router-link
                      class="header__content__sidebar__links_ul_li_a opacity{"
                      to="/outlets/give_mandate"
                      ><i class="fas fa-users mr-2"></i>Outlets</router-link
                    >
                  </li>
                </div>
              </div>
            </li>
            <li>
              <div class="row">
                <div class="col">
                  <button @click="showBlog = !showBlog" class="main__header">
                    <i class="fas fa-blog mr-2"></i>Blog
                    <i v-if="showBlog" class="fas fa-chevron-up"></i>
                    <i class="fas fa-chevron-down" v-else></i>
                  </button>
                </div>
                <div class="col hidden_content" v-if="showBlog">
                  <li class="header__content__sidebar__links_ul_li pl-2">
                    <router-link
                      class="header__content__sidebar__links_ul_li_a opacity{"
                      to="/blog/debate-articles"
                      ><img
                        src="../assets/debate.jpg"
                        height="20"
                        alt=""
                      />Debate</router-link
                    >
                  </li>
                  <li class="header__content__sidebar__links_ul_li pl-2">
                    <router-link
                      class="header__content__sidebar__links_ul_li_a opacity{"
                      to="/blog/challenge"
                      ><img
                        src="../assets/1933917.svg"
                        height="20"
                        alt=""
                      />Challenge</router-link
                    >
                  </li>
                  <li class="header__content__sidebar__links_ul_li pl-2">
                    <router-link
                      class="header__content__sidebar__links_ul_li_a opacity{"
                      to="/blog/articles"
                      ><i class="fas fa-newspaper mr-2"></i
                      >Articles</router-link
                    >
                  </li>
                </div>
              </div>
            </li>

            <li class="header__content__sidebar__links_ul_li">
              <router-link
                class="header__content__sidebar__links_ul_li_a"
                to="/settings"
                ><i class="fa fa-user-cog mr-2"></i>Settings</router-link
              >
            </li>

            <li class="header__content__sidebar__links_ul_li mt-5">
              <button
                class="header__content__sidebar__links_ul_li_a btn"
                @click="logout"
              >
                <i class="fas fa-sign-out-alt mr-2"></i>Logout
              </button>
            </li>
          </ul>
        </div>
      </div>
      <div class="header__dashboard__content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import SideNav from "./SideNav";
import { mapGetters } from "vuex";
import url from "../helpers/url";
import service from "../helpers/service";
import Storage from "../helpers/storage";

export default {
  name: "adminDashboard",
  components: {
    SideNav: SideNav,
  },
  data() {
    return {
      showMandate: false,
      showBlog: false,
      showProfileManagement: false,
    };
  },
  methods: {
    getRoute(url) {
      if(url.includes('adminId')) {
        const id = Storage.get("currentUser").id;
        const newUrl = url.replace('adminId', id)
        return newUrl
      }
      return url
    },
    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },
    logout(e) {
      this.$store.dispatch("logout");
      this.$router.replace("/");
    },
  },
  computed: {
    ...mapGetters(["currentUser", "dashboard"]),
    currentRoute() {
      return this.$router.currentRoute.path;
    },
  },
  mounted() {
    //   var mybutton = document.getElementById("myBtn");
    //    function scrollFunction() {
    //     if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
    //       mybutton.style.display = "block";
    //     } else {
    //       mybutton.style.display = "none";
    //     }
    //   }
    //   window.onscroll = function() {scrollFunction()};
    //
  },
  created() {},
};
</script>

<style scoped>
.opacity {
  opacity: 0.6 !important;
}
.main__header {
  outline: none;
  border: none;
  background-color: transparent;
  font-weight: bold;
  font-size: 1em;
  margin-left: 2.6em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  cursor: pointer;
  color: #7c7979;
}
#accordionSidebar {
  background-color: #ba2428;
}
#myBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 30px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: red;
  color: white;
  cursor: pointer;
  padding: 15px;
  border-radius: 4px;
}

#myBtn:hover {
  background-color: #555;
}
/* 
 */

.main__dashboard {
  margin: 0;
  padding: 0;
  width: 100%;
}
/* 
 * Header component
 *
 */
.header {
  background: #171717;
  color: #ebebeb;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1em 5%;
  width: 100%;
}
/* 
 * Search bar content
 *
 */
.header .header__searchbar {
  position: relative;
  display: flex;
  background-color: #4a4a4a;
  height: 40px;
  border-radius: 20px;
}
.header .header__searchbar .input {
  border: none;
  outline: none;
  height: 40px;
  border: none;
  outline: none;
  width: 100%;
  border-radius: 20px;
  background-color: transparent;
  font-size: 1.3em;
  padding-left: 1em;
  color: #ebebeb;
  display: block;
}
.header .header__searchbar .button {
  border: none;
  outline: none;
  position: absolute;
  background-color: transparent;
  top: 0;
  right: 0;
  font-size: 1.3em;
  color: #ebebeb;
  padding-top: 0.3em;
  padding-right: 1em;
}
/* 
 * Links content
 *
 */
.header .header__links ul {
  list-style: none;
  padding-top: 1em;
}
.header .header__links ul li {
  display: inline;
  color: #ebebeb;
  padding-right: 0.5em;
}
.header .header__links ul li a {
  text-decoration: none;
  color: #ebebeb;
}
/* 
 * header icons
 *
 */
.header .header__icons {
  display: flex;
  flex-direction: row;
}
.header .header__icons button {
  background-color: transparent;
  color: #ebebeb;
  border: none;
  outline: none;
  font-size: 1.3em !important;
}
.header .header__icons .avatar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.header .header__icons .avatar .avatar__avatar {
  width: 35px;
  height: 35px;
  background-color: #fff;
  border-radius: 17.5px;
  display: flex;
  justify-content: center;
}
.header .header__icons .avatar .avatar__text {
  padding-top: 1em;
}
.avatar-text {
  color: #000;
  font-size: 1.4em;
}

/* 
 * content
 */

.header__content {
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: calc(100vh - 100px);
}

/* sidebar*/
.header__content__sidebar {
  width: 100px;
  background-color: #fff;
  width: 300px;
  display: flex;
  flex-direction: column;
  border-right: 0.2rem solid rgba(0, 0, 0, 0.075) !important;
}
.header__content__sidebar__header {
  font-size: 1.5em;
  color: #000;
  font-weight: bolder;
  text-align: center;
  padding-top: 2em;
  padding-bottom: 1em;
}

.header__content__sidebar__links_ul {
  list-style: none;
  padding-left: 0px !important;
}
.header__content__sidebar__links_ul_li {
  position: relative;
  width: 300px;
  color: black;
}
.profile__management {
  padding-left: 2.5em;
  font-size: bolder;
  border: none;
  outline: none;
  background-color: transparent;
}
.header__content__sidebar__links_ul_li_a {
  color: #7c7979;
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  font-size: 1em;
  font-weight: bold;
  transition: all 0.5s;
  width: 300px !important;
  /* border:1px solid red; */
  padding-left: 3em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.header__content__sidebar__links_ul_li_a:hover {
  color: #299c5e;
}
.green {
  color: #299c5e !important;
}

.header__content__sidebar__links_ul_li_a:hover::after,
.active::after {
  content: " ";
  background-color: #299c5e;
  right: 0;
  position: absolute;
  height: 90%;
  width: 8px;
  top: 5%;
  bottom: 5%;
}

/* main content */
.header__dashboard__content {
  width: 100%;
  padding: 3em 5%;
  background-color: #efefef;
}
/* 
 * media queries for top header
 */
@media screen and (max-width: 900px) {
  .header .header__links {
    display: none;
  }
}
</style>
