<template>
<ul class="navbar-nav  sidebar sidebar-dark accordion" id="accordionSidebar">
  
  <a class="sidebar-brand d-flex align-items-center justify-content-center my-5">
    <div class="sidebar-brand-icon">
   
      <img src="../assets/afcfta.png" height="80" alt="">
    </div>
  </a>

  <hr class="sidebar-divider my-0" />
  <li class="nav-item" v-for="(link,key) in dashboard.dashboard.links.sidebar" :key="key">
    <router-link class="nav-link " :to="link.route" >
      <i :class="`${link.icon}`"></i>
      <span class="pl-2
      ">{{link.text}}</span>
    </router-link>
  </li>
  <li class="nav-item">
        <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
          <i class="fas fa-id-card-alt"></i>
          <span class="pl-2">Profile Management</span>
        </a>
        <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
          <div class="bg-white py-2 collapse-inner rounded">
            <h6 class="collapse-header">profile Management:</h6>
            <router-link class="collapse-item" :to="`/admins/entities`"> <i class="fas fa-users"></i> Entities</router-link>
            <router-link class="collapse-item" :to="`/admins/users`"> <i class="fas fa-sitemap"></i> Users</router-link>
          </div>
        </div>
      </li>
      <li v-if="dashboard.dashboard.links.mandate" class="nav-item">
    <a class="nav-link collapsed" 
      data-toggle="collapse" data-target="#collapseUtilities" aria-expanded="true" aria-controls="collapseUtilities">
      <i :class="`${dashboard.dashboard.links.mandate.icon}`"></i>
      <span class="pl-2
      ">{{dashboard.dashboard.links.mandate.title}}</span>
    </a>
    <div id="collapseUtilities" class="collapse" aria-labelledby="headingUtilities" data-parent="#accordionSidebar">
          <div class="bg-white py-2 collapse-inner rounded">
            <h6 class="collapse-header">{{dashboard.dashboard.links.mandate.title}}:</h6>
            <router-link class="collapse-item"
                v-for="mandate in dashboard.dashboard.links.mandate.sublinks" :key="mandate.text"
                :to="mandate.route">{{mandate.text}}</router-link>
          </div>
        </div>
  </li>
 <li class="nav-item">
        <a class="nav-link collapsed" href="#" data-toggle="collapse" data-target="#collapsethree" aria-expanded="true" aria-controls="collapseTwo">
          <i class="fas fa-blog"></i>
          <span class="pl-2">Blog</span>
        </a>
        <div id="collapsethree" class="collapse" aria-labelledby="headingTwo" data-parent="#accordionSidebar">
          <div class="bg-white py-2 collapse-inner rounded">
            <h6 class="collapse-header">Blog:</h6>
            <router-link class="collapse-item" :to="`/blog/debate-articles`">
              <img src="../assets/debate.jpg" height="20" alt="">
             Debate
            </router-link>
            <router-link class="collapse-item" :to="`/blog/challenge`">
            <img src="../assets/1933917.svg" height="20" alt="">
              Challenge
              </router-link>
              <router-link class="collapse-item" :to="`/blog/articles`">
                <i class="fas fa-newspaper"></i>
                Articles
              </router-link>
          </div>
        </div>
      </li>
      <li class="nav-item">
        <router-link :to="`/settings`" class="nav-link">
          <i class="fa fa-user-cog"></i>
          <span> Settings</span>
        </router-link>
      </li>
  <hr class="sidebar-divider" />

  <!-- Sidebar Toggler (Sidebar) -->
  <div class="text-center d-none d-md-inline">
    <button style="color:red" class="rounded-circle border-0" id="sidebarToggle"></button>
  </div>
</ul>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name:'SideNav',
 computed: { ...mapGetters(["dashboard"]) },
  created() {
  //  console.log('roles from sidenavigation', this.dashboard)
  }
}
</script>